<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {fetchSectorsApi} from "@/api/common";
import { required, maxLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
export default {
  page: {
    title: "Nouveau bloc",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  import : {fetchSectorsApi},
  data() {
    return {
      title: "Nouveau bloc",
      items: [
        {
          text: "Infrastructure",
          href: "/"
        },
        {
          text: "Blocs",
          href: "/"
        },
        {
          text: "Nouveau",
          active: true
        }
      ],

      sectors: [],
      blocForm: {
        blocName: "",
        blocClass: "",
        blocType: "",
        sector: "",
      },
      
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    blocForm: {
      blocName: { required, maxLength: maxLength(64) },
      blocClass: { required },
      blocType: { required },
      sector: { required },
    },
    
  },
  mounted() {
    this.fetchSectors();
  },
  methods: {
    fetchSectors(){
      fetchSectorsApi()
        .then((res) => (this.sectors = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
      
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http.post('/infrastructure/blocs/store', this.blocForm)

      .then((res) => {
          var status = res.data.original.status;
          switch(status){
            case 200: 
              this.$toast.success(res.data.original.msg);
              router.push({ name: 'base.infrastructures.blocs' });
              
            break;

            case 500: 
              this.$toast.warning(res.data.original.msg);
            break;
          }
      })
      .catch((error) => {
          this.$toast.error(error.message);
      }).finally(() => {
      });
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Désignation</label>
                    <input
                      id="deptName"
                      v-model="blocForm.blocName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Mark"
                      :class="{ 'is-invalid': submitted && $v.blocForm.blocName.$error }"
                    />
                    <div v-if="submitted && $v.blocForm.blocName.$error" class="invalid-feedback">
                      <span v-if="!$v.blocForm.blocName.required">Le nom est obligatoire.</span>
                      <span v-if="!$v.blocForm.blocName.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Classification</label>
                    <select 
                    v-model="blocForm.blocClass"
                    :class="{ 'is-invalid': submitted && $v.blocForm.blocClass.$error }" 
                    class="custom-select">
                        <option selected>Sélectionner une classe</option>
                        <option value="ordinaire">Ordinaire</option>
                        <option value="vip">VIP</option>
                      </select>
                    <div v-if="submitted && $v.blocForm.blocClass.$error" class="invalid-feedback">
                      <span v-if="!$v.blocForm.blocClass.required">La classification est obligatoire.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Type</label>
                    <select v-model="blocForm.blocType" :class="{ 'is-invalid': submitted && $v.blocForm.blocClass.$error }" class="custom-select">
                        <option selected>Sélectionner un type</option>
                        <option value="travail">Bloc administratif</option>
                        <option value="hebergement">Bloc Hébergement</option>
                      </select>
                    <div v-if="submitted && $v.blocForm.blocType.$error" class="invalid-feedback">
                      <span v-if="!$v.blocForm.blocType.required">Le type est obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputCity">Secteur *</label>
                    <multiselect
                      v-model="blocForm.sector"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="sectors"
                      placeholder="Select Sector"
                      :class="{ 'is-invalid': submitted && $v.blocForm.sector.$error }"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ sector }">{{
                        sector.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.blocForm.sector.$error" class="invalid-feedback">
                      <span v-if="!$v.blocForm.sector.required">Le secteur est obligatoire.</span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>